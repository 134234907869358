export const staging = {
  host: 'staging.ma-navigator.com',
  imageFluxHost: 'p1-ea9a31b0.imageflux.jp',
  gtmId: 'GTM-W7SJQFL',
  ENABLE_MICROCMS: false,
  ENABLE_NEWT: true,
  IS_SHOW_TSR_SLUG: ['developer', 'demo'],
  ENABLE_ALLIANCE_SLUG: ['developer', 'demo'],
  NAVI_TENANT_SLUG: 'developer',
  ENABLE_LONGLIST_EXPORT_PATTERN: ['developer', 'demo'],
  GOOGLE_API_CLIENT_ID: '472729695253-sc1o7fm3cje1s7vkldghg8628e8nnecr.apps.googleusercontent.com',
}
