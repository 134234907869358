import { useMemo, useCallback, useEffect, useContext, createContext, FC } from 'react'
import axios, { AxiosInstance, AxiosError } from 'axios'
import { useFlash } from 'context/flashContext'
import { useLoading } from 'context/loadingContext'
import { common as t } from 'public/locales/ja/common'
import { sendSentryLog } from 'lib/sentry'

interface AxiosContextType {
  axiosMask: AxiosInstance
  axiosProgress: AxiosInstance
  axiosNone: AxiosInstance
}

const AxiosContext = createContext<AxiosContextType>({
  axiosMask: axios,
  axiosProgress: axios,
  axiosNone: axios,
})

export function useAxios(): AxiosContextType {
  return useContext(AxiosContext)
}

export const AxiosProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const { setFlash } = useFlash()
  const { setLoading } = useLoading()
  const waitingRequests = useMemo<true[]>(() => [], [])
  const axiosMask = useMemo(() => axios.create({}), [])
  const axiosProgress = useMemo(() => axios.create({}), [])
  const axiosNone = useMemo(() => axios.create({}), [])

  const injectInterceptors = useCallback(
    function (instance: AxiosInstance, loadingEffect: 'mask' | 'progress' | 'none'): void {
      instance.interceptors.request.use(
        (config) => {
          waitingRequests.push(true)
          if (loadingEffect !== 'none') {
            setLoading(loadingEffect)
          }
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )
      instance.interceptors.response.use(
        (res) => {
          waitingRequests.pop()
          if (waitingRequests.length === 0) {
            setLoading(false)
          }
          return res
        },
        (error: AxiosError) => {
          waitingRequests.pop()
          if (waitingRequests.length === 0) {
            setLoading(false)
          }
          sendSentryLog(error)
          if (error.isAxiosError) {
            const statusCode = error.response?.status
            if (statusCode === 404) {
              setLoading(false)
            } else {
              if (statusCode === 401) {
                setFlash({ type: 'error', message: '', isTimeout: true })
              } else if (statusCode === 403) {
                setFlash({ type: 'error', message: t.error.unauthorized })
              } else if (statusCode === 500) {
                setFlash({ type: 'error', message: t.error.serverError })
              }
            }
          }
          return Promise.reject(error)
        }
      )
    },
    [waitingRequests, setLoading, setFlash]
  )

  useEffect(() => {
    injectInterceptors(axiosMask, 'mask')
    injectInterceptors(axiosProgress, 'progress')
    injectInterceptors(axiosNone, 'none')
  }, [axiosMask, axiosProgress, axiosNone, waitingRequests, setLoading, setFlash, injectInterceptors])
  const value = { axiosMask, axiosProgress, axiosNone }
  return <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>
}
